import React from "react"
import { 
  useStaticQuery, 
  graphql 
} from "gatsby"
import Layout from "../layout/layout"
import SEO from "../seo/seo"
import About from "../components/index/about"
import Box from '@material-ui/core/Box';

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query BrandQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  
  return (
    <Layout>
      <SEO title={`About | ${data.site.siteMetadata.title}`} />
      <Box
        py={7}
      >
        <div className="page">
          <About />
          {/* <QualityWork /> */}
        </div>
      </Box>
      
    </Layout>
  )
}

export default AboutPage
